import { Container, Navbar, Nav } from "react-bootstrap";
import { useContext } from "react";
import { CartContext } from "../CartContext";
import { NavLink } from "react-router-dom";
import Checkout from "./Checkout";

function NavbarComponent() {
  const cart = useContext(CartContext);

  return (
    <>
      <Navbar       style={{
        color: 'white',
        fontFamily: 'serif',
        fontSize: 20,
        backgroundColor: 'rgba(183, 193, 172, 1)',
        position: 'fixed', // Fix the navbar at the top
        top: 0,
        width: '100%',
        zIndex: 1000, // Ensure it stays on top of other elements
      }} expand="lg">
        <Container style={{ backgroundColor: 'rgba(183, 193, 172, 1)' }}>
          <Navbar.Brand>
            <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/groups/cozyloops_new_logo.jpg" alt="logo" width={100} height={100} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="w-100 justify-content-between">
              <NavLink className="nav-link mx-2" to="/home">
                Home
              </NavLink>
              <NavLink className="nav-link mx-2" to="/coasters">
                Coasters
              </NavLink>
              <NavLink className="nav-link mx-2" to="/bags">
                <Nav.Item>Bags & Pouches</Nav.Item>
              </NavLink>
              <NavLink className="nav-link mx-2" to="/winter">
                <Nav.Item>Winter Accessories</Nav.Item>
              </NavLink>
              <NavLink className="nav-link mx-2" to="/cardecor">
                <Nav.Item>Car Decors & Key Chains</Nav.Item>
              </NavLink>
              <NavLink className="nav-link mx-2" to="/contact">
                <Nav.Item>Contact</Nav.Item>
              </NavLink>
              <NavLink className="nav-link mx-2" to="/about">
                <Nav.Item>About</Nav.Item>
              </NavLink>
              {/* Add more NavLinks as needed */}
            </Nav>
          </Navbar.Collapse>
          <Checkout/>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;
import React, {Component }  from 'react';
import axios from 'axios';
import { Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import Search from '../components/Search';


//const images = [{ src: {leaf} }, { src: {leaf} }];


class AllProducts extends Component {

    state = {
        image_array: [],
        searchTerm: ''
    };

    handleSearchChange = (term) => {
        this.setState({ searchTerm: term });
    };

    
    async loadData () {
        const imageArrayData = [];
        const endpoints = [
            'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcoasters',
            'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetbags',
            'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetwinter',
            'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcardecor'
          ];
        
          const requests = endpoints.map(endpoint => axios.get(endpoint));
        
          try {
            const responses = await axios.all(requests);
        
            for (const response of responses) {
                imageArrayData.push(...JSON.parse(response.data.body));
            }
            console.log('response from allproducts ---------------------', imageArrayData);
            this.setState({image_array : imageArrayData})
          } catch (error) {;
            console.error('Error fetching data:', error);
          }

    }
    componentDidMount () {
       
        this.loadData();
    }

    render() {    
        const { image_array, searchTerm } = this.state;
        console.log('image_array in render', image_array);
        const filteredProducts = image_array.filter(product =>
            product.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return (
          <><div className="abstract" onContextMenu={(e) => e.preventDefault()}>
        </div><h1 align="center" className="p-3">
          <div className='product-header' >
                  <h1 className='product-title'>Shop your Crochet Products!</h1>
                  <Search searchTerm={searchTerm} onSearchChange={this.handleSearchChange} />
           </div>
            {console.log('carDecorArray', this.state.image_array)}
          </h1><Row xs={1} md={2} xl={3} className="g-4">
            {filteredProducts.map((product, idx) => (
              <Col align="center" key={idx}>
                <ProductCard product={product} />{" "}
              </Col>
            ))}
          </Row></>
      );
    }

    styleSmall(){
        return ({
           border:'5px solid #FAC9B8',
           width: '175px',
           height: '100%',

        });
     }
}

export default AllProducts;
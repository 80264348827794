import React from 'react';
import './About.css';

function About() {
  return (
    <>
      <br />
      <br />
      <h1 className="about-header">Welcome to Cozyloops!</h1>
      <p className="about-text">
        <strong>
          An ultimate destination for artisan-crafted crochet treasures. At CozyLoops, we believe in the warmth and magic of handmade creations, each piece infused with love and care. Our curated selection spans from luxurious yarns and intricate patterns to finished goods that add a touch of coziness to your life. Whether you're an experienced crocheter or new to the world of loops and stitches, we are here to inspire your creative journey with our quality products and passionate community. Dive in and discover the cozy charm that makes each loop come alive.
        </strong>
      </p>
    </>
  );
}

export default About;
import React from 'react';
import Button from "react-bootstrap/Button";
import { Link } from 'react-router-dom';
import './Categories.css';

class Categories extends React.Component {
    render() {
        return (
            <>
            <div style={{backgroundColor: 'green', backgroundImage: 'https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/Coaster_Green(Flower)_14.99_1QaSJSGuk3Vz1JU9ODnBn2E9.jpg'}}>
            </div>
            <div style={{ display: 'flex', marginTop: '50px'}}>
            <div style={{ flex: '2', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(183, 193, 172, 1)', fontFamily: 'Cursive', padding: '20px' }}>
                        <h1 classname= "welcome-message">
                            Welcome to CozyLoops.com! <br /> Embrace the warmth of elegant crochet creations.
                        </h1>
                        <br />
                        <br />
                        <div style={{ alignSelf: 'flex-end' }}>
                        <Link to="/allproducts">
                            <Button variant="primary" style={{ fontSize: '30px', padding: '10px 20px', fontFamily: 'Serif', backgroundColor: 'rgba(44, 48, 4, 0.8)' }}>Shop Now</Button>
                        </Link>
                        </div>
                    </div>
                <div className="welcome-image" style={{ flex: '2'}}>
                    <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/Coaster_Green(Flower)_14.99_1QaSJSGuk3Vz1JU9ODnBn2E9.jpg" alt="Banner" style={{ width: '100%', height: '500px' }} />
                    
                </div>
            </div><><div />

            
            <br /><br />
            <br /><br />
            <br /><br />
            <div style={{ backgroundColor: 'rgba(183, 193, 172, 1)'}}>
                <br></br>
                <h2 style={{color:  'white', fontFamily: 'serif' , fontSize: 30, justifyContent: 'center', marginLeft: '20px'}}>Shop by Featured Categories</h2>
                <div className="categories-container">
                    <div className="row">
                        <div className="col-lg-3 parent">
                            <div className="image-container">
                                <div className="image-name">Coasters</div>
                                <Link to="/coasters" style={{ textDecoration: 'none' }} className="link">
                                    <img src="https://francobucket1.s3.amazonaws.com/crochetpics/coasters/Coaster_Red_14.99_1QaSJTGuk3Vz1JU9FIzMoaIq.jpg" alt="coasters" className="category-image"></img>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 parent">
                            <div className="image-container">
                                <div className="image-name">Bags & Pouches</div>
                                <Link to="/bags" style={{ textDecoration: 'none' }} className="link">
                                    <img src="https://francobucket1.s3.amazonaws.com/crochetpics/bags/AirPod%20Pouch_12.00_1QaSEEGuk3Vz1JU9BySRxXJz.jpg" alt="bags" className="category-image"></img>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 parent">
                            <div className="image-container">
                                <div className="image-name">Winter Accessories</div>
                                <Link to="/winter" style={{ textDecoration: 'none' }} className="link">
                                    <img src="https://francobucket1.s3.amazonaws.com/crochetpics/winter/Blue%20Beanie_14.99_1QbVDNGuk3Vz1JU9lek6wQzC.jpg" alt="winter" className="category-image"></img>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-3 parent">
                            <div className="image-container">
                                <div className="image-name">Car Decors & Key Chains</div>
                                <Link to="/cardecor" style={{ textDecoration: 'none' }} className="link">
                                    <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/cardecor/Car+Hanger+Cream+Jellyfish_14.99_1QbVVJGuk3Vz1JU9zddpbcLa.jpg" alt="car decor" className="category-image"></img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div></></>
        );
    }
}

export default Categories;
function Home() {
  return (
    <div style={{ display: 'flex', marginTop: '20px' }}>
    <div style={{ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(183, 193, 172, 1)', color: 'rgba(44, 48, 4, 0.8)', fontFamily: 'Cursive' }}>
        <h1>Welcome to CozyLoops.com! <br/> Embrace the warmth of our beautifully crafted crochet creations.
        </h1>
      </div>
      <div style={{ flex: '1' }}>
        <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/Coaster_Green(Flower)_14.99_1QaSJSGuk3Vz1JU9ODnBn2E9.jpg" alt="Banner" style={{ width: '100%', height: '500px' }} />
      </div>
    </div>
  );
}

export default Home;

// Search.js
import React from 'react';

const Search = ({ searchTerm, onSearchChange }) => {
    return (
        <div className="search-container">
            <input className='search-input'
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                style={{ width: '250px', padding: '5px', fontSize: '20px', border: '1px solid #ccc', borderRadius: '50px', borderColor: 'green' }}
            />
        </div>
    );
};
export default Search;
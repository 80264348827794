import { Card, Button, Form, Row, Col, ModalBody, Modal, ModalHeader } from "react-bootstrap";
import { CartContext } from "../CartContext";
import { useContext, useState } from "react";
import DefaultImage from "../images/ProductStoreImage.png";
import './ProductCard.css';

function ProductCard(props) {
  const product = props.product;
  const cart = useContext(CartContext);
  const productQuantity = cart.getProductQuantity(product.id);
  const isOutOfStock = product.title.toLowerCase().includes('out of stock');
  console.log(cart.items, product);
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  return (
    <><Card>
      {/* If we get image from product OR default image will shown */}
      <Card.Img variant="top" src={product?.imgUrl || DefaultImage} width="100%" height="500px" style={{ objectFit: "cover", cursor: 'pointer' }} onClick={handleOpenModal} />
      <Card.Body>
        <Card.Title>{product.title}</Card.Title>
        <Card.Text>{product.description}</Card.Text>
        <Card.Text>${product.price}</Card.Text>
        {productQuantity > 0 ? (
          <>
            <Form as={Row}>
              {" "}
              <Form.Label column="true" sm="25">
                <h5 style={{ color: 'blue' }}>Added to Cart</h5>
              </Form.Label>
            </Form>
            <Button className="remove-from-cart-button" variant="danger" onClick={() => cart.deleteFromCart(product.id)}>
              Remove from cart
            </Button>
          </>
        ) : (
          <Button className="add-to-cart-button" disabled={isOutOfStock} variant="primary" onClick={() => cart.addOneToCart(product.id)}>
            Add To Cart
          </Button>
        )}
      </Card.Body>
    </Card>
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>

        <Modal.Title>{product.title}</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <img src={product?.imgUrl || DefaultImage} alt={product.title} style={{width: "100%", height: "100%"} } />
      </ModalBody>
    </Modal>
    </>
  );
}

export default ProductCard;

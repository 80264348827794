import axios from 'axios';
import { useState } from "react";
let productsArray = [];
 productsArray = [
  {
    id: "price_1QTTj5Guk3Vz1JU9twIHpYdf",
    title: "Coaster",
    description: "",
    price: 9.99,
    imgUrl: "/images/coaster.jpg",
  },
  {
    id: "price_1Qa5ePGuk3Vz1JU9mGLLviPD",
    title: "Bag",
    description: "",
    price: 19.99,
    imgUrl: "/images/bag.jpg",
  },
  {
    id: "price_1QUdp5Guk3Vz1JU9NQmKcZzi",
    title: "CarDecor",
    description: "",
    price: 14.99,
    imgUrl: "/images/cardecor.jpg",
  },
];
//const productsArray = loadData();


async function fetchData() {
  //fetchCoasterData();
  //fetchBagData();
  //fetchBagData();
  const endpoints = [
    'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcoasters',
    'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetbags',
    'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetwinter',
    'https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcardecor'
  ];

  const requests = endpoints.map(endpoint => axios.get(endpoint));

  try {
    const responses = await axios.all(requests);

    for (const response of responses) {
      productsArray.push(...JSON.parse(response.data.body));
    }

  } catch (error) {
    console.error('Error fetching data:', error);
  }

}

function fetchCoasterData() {
  axios.get('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcoasters')
  .then( response => {

      productsArray.push(JSON.parse(response.data.body));
      //productsArray.push(coasterData);
      console.log('converted from coasters bucket data ---------------------', productsArray );
  
  })
  .catch(error => {
      console.log(error);
      console.log('some error')
  })

}

function fetchBagData() {
  axios.get('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetbags')
  .then( response => {
      productsArray.push(JSON.parse(response.data.body));
      console.log('converted from bag bucket data ---------------------', productsArray );
  
  })
  .catch(error => {
      console.log(error);
      console.log('some error')
  })

}


function getProductData(id) {
  
  let productData = productsArray.find((product) => product.id === id);

  if (productData == undefined) {
    console.log("Product data does not exist for ID: " + id);
    return undefined;
  }
  return productData;
}

export { fetchData, productsArray, getProductData };

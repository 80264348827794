import { Button, Container, Navbar, Modal, Nav } from "react-bootstrap";
import { useState, useContext } from "react";
import { CartContext } from "../CartContext";
import CartProduct from "./CartProduct";
import { NavLink } from "react-router-dom";
import Logo from '../logo.png';
import './CartProduct.css';

function Footer() {
  const cart = useContext(CartContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkout = async () => {
    await fetch("https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochet-payment", {
  //  await fetch("http://localhost:5002/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: cart.items }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        const responseBody = JSON.parse(response.body);
        console.log(responseBody);
        if (responseBody.url) {  
          window.location.assign(responseBody.url); // Forwarding user to Stripe
        } else {
          window.location.assign("https://cozyloops.com/error");
        }
      });
  };

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
  const isQualifiedForFreeShipping = cart.items.length > 1;

  return (
    <>
      {productsCount > 0 ?
      (<Navbar expand="sm">
        <Container>
            <Button style={{ backgroundColor: 'rgba(161, 85, 85, 1)' }} onClick={handleShow}>Checkout ({productsCount} Items)</Button>
        </Container>
      </Navbar>) : "" }
      <div class="modal">
  <div class="modal-body">
      <Modal show={show} onHide={handleClose}>
        {" "}
        <Modal.Header closeButton>
          <Modal.Title>Shopping Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {productsCount > 0 ? (
            <>
      <div className="checkout">
        
      {isQualifiedForFreeShipping ? (
        <p style={{ color: 'rgba(161, 85, 85, 1)' }}>Congrats! You are qualified for free shipping!</p>
      ) : (
        <p style={{ color: 'rgba(161, 85, 85, 1)' }} >Add one more item to qualify for free shipping!</p>
      )}
      {/* Render the rest of your checkout component */}
    </div>
              <p>Items in your cart:</p>
              {cart.items.map((currentProduct, idx) => (
                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
              ))}

              <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>

              <Button variant="success" onClick={checkout}>
                Purchase items!
              </Button>
            </>
          ) : (
            <h1>There are no items in your cart!</h1>
          )}
        </Modal.Body>
      </Modal>
      </div>
</div>
    </>
  );
}

export default Footer;

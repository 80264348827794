import React, {Component} from 'react';
import './Contact.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';



class Contact extends Component {

    constructor() {
        super();
        this.state = {
           
                name: '',
                email:'',
                message:'',
                successMessage:''
            
        }
    }


    changeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({...this.state,[name]: value});
        
    }

    submitForm = (event) => {
        console.log(this.state);
        event.preventDefault(); 
        this.postData();
    }

    postData = (event) => {
        axios.post('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochet-contact', {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message
        })
        .then((response) => {
            if (response.status === 200) {
                this.setState({
                    name: '',
                    email: '',
                    message: '',
                    successMessage: 'Message has been Submitted. Your query will be responded shortly!',
                });
            } else {
                this.setState({name: '', email: '', message: '', successMessage : 'Error Occured. Please try again later.'})
            }
            console.log(response)
            
        })
        .catch(error => {
            this.setState({name: '', email: '', message: '', successMessage : 'Error Occured. Please try again later.'})
        })

    }

    

    render () {
        return (
            <div className="contact-page">
                <div class="container-fluid">
                    <div class="row ">ˆ
                        <div class="col-lg-6 d-flex" >
                            <div>
                                <img src="https://francobucket1.s3.us-east-2.amazonaws.com/crochetpics/coasters/Coaster_Green(Flower)_14.99_1QaSJSGuk3Vz1JU9ODnBn2E9.jpg" alt="test" className="silh"></img>
                            </div>
                            <div>
                            <form class="contact-form" onSubmit={this.submitForm}>

                                <div className="form-group">
                                    <label for="name"><strong>NAME</strong></label>
                                    <input onChange={this.changeHandler} name="name" type="text" class="form-control wider-input"></input>
                                </div>
                                <div className="form-group w-50 wider-input">
                                    <label for="exampleInputEmail1"><strong>EMAIL ADDRESS</strong></label>
                                    <input onChange={this.changeHandler} name="email" type="email" class="form-control wider-input"></input>     
                                </div>
                                
                                <div className="form-group w-50">
                                    <label for="message"><strong>MESSAGE</strong></label>
                                    <textarea onChange={this.changeHandler} name="message" type="text" class="form-control wider-input"></textarea>
                                </div>
                                <button style={{ fontSize: '30px', padding: '10px 20px', fontFamily: 'Serif', backgroundColor: 'rgba(44, 48, 4, 0.8)' }} type="submit"  class="btn btn-primary w-40">Submit</button>
                                <br/>
                                <br/>

                            </form>
                            <span style={{ fontSize: '20px', fontFamily: 'Serif', color: 'rgba(255, 255, 172, 1)'}} className="text-success">{this.state.successMessage}</span>
                                <br></br>
                            </div> 
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Contact;
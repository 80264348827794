import React, {Component }  from 'react';
import axios from 'axios';
import { Row, Col } from "react-bootstrap";
import ProductCard from "../components/ProductCard";
import Search from '../components/Search';
import './Products.css';

//const images = [{ src: {leaf} }, { src: {leaf} }];


class Coasters extends Component {

    state = {
        image_array: [],
        searchTerm: ''
    };

    handleSearchChange = (term) => {
        this.setState({ searchTerm: term });
    };

    
    loadData () {
        axios.get('https://ld40lca5hc.execute-api.us-east-1.amazonaws.com/Prod/crochetcoasters')
        .then( response => {
            const myJSONData = JSON.parse(response.data.body)
            console.log('response from coasters bucket data ---------------------', myJSONData);
            this.setState({image_array : myJSONData})
        
        })
        .catch(error => {
            console.log(error);
            console.log('some error')
        })

    }
    componentDidMount () {
       
        this.loadData();
    }

    render() {    
        const { image_array, searchTerm } = this.state;
        console.log('image_array in render', image_array);
        const filteredProducts = image_array.filter(product =>
            product.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return (
            <><div className="abstract" onContextMenu={(e) => e.preventDefault()}>
          </div><h1 align="center" className="p-3">
              <div className='product-header' >
                    <h1 className='product-title'>Shop your Coasters!</h1>
                    <Search searchTerm={searchTerm} onSearchChange={this.handleSearchChange} />
             </div>
              {console.log('coasterArray', this.state.image_array)}
            </h1><Row xs={1} md={2} xl={3} className="g-4">
              {filteredProducts.map((product, idx) => (
                <Col align="center" key={idx}>
                  <ProductCard product={product} />{" "}
                </Col>
              ))}
            </Row></>
        );
    }

    styleSmall(){
        return ({
           border:'5px solid #FAC9B8',
           width: '175px',
           height: '100%',

        });
     }
}

export default Coasters;